<template>
  <v-card flat tile>
    <v-data-table dense disable-sort hide-default-footer :items-per-page="-1" :headers="headers" :items="items"
                  hide-default-header :expanded.sync="expanded" item-key="index">
      <template v-slot:header="{props: {headers}}">
        <thead>
        <tr>
          <th v-for="h in headers" :key="h.value" :class="'text-' + h.align" style=" font-size: 19px; background-color: #414141">
            {{ h.text }}
          </th>
        </tr>
        </thead>
      </template>
      <!--suppress HtmlUnknownAttribute -->
      <template v-slot:item.place="{headers, item}">
        <td v-if="item.index >= 0" class="text-right" style="font-size: 19px" nowrap>
          {{ item.place }}
        </td>
        <td v-else class="text-center" style="font-size: 19px; background-color: #414141" nowrap>
          {{ item.place }}
        </td>
      </template>
      <!--suppress HtmlUnknownAttribute -->
      <template v-slot:item.name="{headers, item}">
        <td v-if="item.index >= 0" class="text-left" style="font-size: 19px" nowrap>
          {{ item.name }}
        </td>
        <td v-else class="text-center" style="font-size: 19px; background-color: #414141" nowrap>
          {{ item.name }}
        </td>
      </template>
      <!--suppress HtmlUnknownAttribute -->
      <template v-slot:item.a1="{headers, item}">
        <td v-if="item.index >= 0" :class="[item['a1c']]" class="text-center" style="font-size: 19px" nowrap>
          {{ $utils.results.parseResult(item['a1'], event) }}
        </td>
        <td v-else :class="[item['a1c']]" class="text-center" style="font-size: 19px; background-color: #414141" nowrap>
          {{ $utils.results.parseResult(item['a1'], event) }}
        </td>
      </template>
      <!--suppress HtmlUnknownAttribute -->
      <template v-slot:item.a2="{headers, item}" class="text-center">
        <td v-if="item.index >= 0" :class="[item['a2c']]" class="text-center" style="font-size: 19px" nowrap>
          {{ $utils.results.parseResult(item['a2'], event) }}
        </td>
        <td v-else :class="[item['a2c']]" class="text-center" style="font-size: 19px; background-color: #414141" nowrap>
          {{ $utils.results.parseResult(item['a2'], event) }}
        </td>
      </template>
      <!--suppress HtmlUnknownAttribute -->
      <template v-slot:item.a3="{headers, item}" class="text-center">
        <td v-if="item.index >= 0" :class="[item['a3c']]" class="text-center" style="font-size: 19px" nowrap>
          {{ $utils.results.parseResult(item['a3'], event) }}
        </td>
        <td v-else :class="[item['a3c']]" class="text-center" style="font-size: 19px; background-color: #414141" nowrap>
          {{ $utils.results.parseResult(item['a3'], event) }}
        </td>
      </template>
      <!--suppress HtmlUnknownAttribute -->
      <template v-slot:item.a4="{headers, item}" class="text-center">
        <td v-if="item.index >= 0" :class="[item['a4c']]" class="text-center" style="font-size: 19px" nowrap>
          {{ $utils.results.parseResult(item['a4'], event) }}
        </td>
        <td v-else :class="[item['a4c']]" class="text-center" style="font-size: 19px; background-color: #414141" nowrap>
          {{ $utils.results.parseResult(item['a4'], event) }}
        </td>
      </template>
      <!--suppress HtmlUnknownAttribute -->
      <template v-slot:item.a5="{headers, item}" class="text-center">
        <td v-if="item.index >= 0" :class="[item['a5c']]" class="text-center" style="font-size: 19px" nowrap>
          {{ $utils.results.parseResult(item['a5'], event) }}
        </td>
        <td v-else :class="[item['a5c']]" class="text-center" style="font-size: 19px; background-color: #414141" nowrap>
          {{ $utils.results.parseResult(item['a5'], event) }}
        </td>
      </template>
      <!--suppress HtmlUnknownAttribute -->
      <template v-slot:item.best="{headers, item}">
        <td v-if="highlight === 'b' || item.index < 0" class="font-weight-bold text-center"
            style="font-size: 19px; background-color: #414141" nowrap>
          {{ $utils.results.parseResult(item['best'], event) }}
        </td>
        <td v-else class="font-weight-bold text-center" style="font-size: 19px" nowrap>
          {{ $utils.results.parseResult(item['best'], event) }}
        </td>
      </template>
      <!--suppress HtmlUnknownAttribute -->
      <template v-slot:item.avg="{headers, item}" nowrap>
        <td v-if="highlight === 'a' || item.index < 0" class="font-weight-bold text-center"
            style="font-size: 19px; background-color: #414141">
          {{ $utils.results.parseResult(item['avg'], event) }}
        </td>
        <td v-else class="font-weight-bold text-center" style="font-size: 19px" nowrap>
          {{ $utils.results.parseResult(item['avg'], event) }}
        </td>
      </template>
      <template v-slot:expanded-item="{headers, item}">
        <td :colspan="headers.length - 2 ">&nbsp;</td>
        <td v-if="item.expand != null" style="font-size: 19px; background-color: #414141" nowrap class="text-center">
          <span class="success--text">{{ $utils.results.parseResult(item.expand.best, event) }}</span>
          /
          <span class="error--text">{{ $utils.results.parseResult(item.expand.worst, event) }}</span>
        </td>
        <td v-else>&nbsp;</td>
        <td>&nbsp;</td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
// http://localhost:8080/admin/obs/round?key=HYYgzPKmjUs2n3pPcucdYvTabbaTC7RaGG377C428kS6dRK2by6LSPxFBxq4&competition=PucharPolskiOnlineFINAL2021&round=333-r3
export default {
  name: 'AdminOBSRound',
  data: () => ({
    key: '', competition: '', round: '', efficiency: false,
    highlight: 'a', event: 0, sse: null, data: {},
    items: [], headers: [], expanded: []
  }),
  created() {
    this.key = this.$route.query.key;
    this.competition = this.$route.query.competition;
    this.round = this.$route.query.round;
    this.efficiency = this.$route.query.efficiency;
    this.sse = new EventSource(this.$http.defaults.baseURL + '/api/admin/obs/round' + this.query());
    let self = this;
    this.sse.onmessage = (event) => {
      self.update(JSON.parse(event.data));
    };
    this.$vuetify.theme.dark = true;
  },
  deleted() {
    this.sse.close();
  },
  methods: {
    query() {
      return '?key=' + this.key + '&competition=' + this.competition + '&round=' + this.round;
    },
    async update(data) {
      this.data = data;
      this.expanded = [];
      const format = data.format['id']; // 1, 2, 3, a, m
      this.updateHeaders(format);
      this.highlight = ['a', 'm'].includes(format) ? 'a' : 'b';
      this.event = this.$utils.events.EVENT_ICONS.indexOf(data.event.id);

      // parse results
      let results = [];
      for (let i = 0; i < data.results.length; i++) results.push({
        place: data.results[i]['ranking'], name: data.results[i]['person']['name'], avg: data.results[i]['average'],
        best: data.results[i]['best'], a1: data.results[i]['attempts'][0] || 0, a2: data.results[i]['attempts'][1] || 0,
        a3: data.results[i]['attempts'][2] || 0, a4: data.results[i]['attempts'][3] || 0,
        a5: data.results[i]['attempts'][4] || 0, index: i, expand: null
      });

      // add extra info if format is 'a'
      if (format === 'a') {
        for (let i = 0; i < results.length; i++) {
          let all = [results[i]['a1'], results[i]['a2'], results[i]['a3'], results[i]['a4'], results[i]['a5']]
          let solves = all.filter((item) => item !== 0).map((item) => item > 0 ? item : 999999999999);
          let dnf = all.filter((item) => item < 0);

          if (solves.length === 5) continue;

          if (dnf.length > 1)
            results[i]['avg'] = -1;

          if (dnf.length < 2 && solves.length === 3)
            results[i]['avg'] = solves[0] + solves[1] + solves[2] - Math.min(...solves) - Math.max(...solves);

          if (dnf.length < 2 && solves.length === 4) {
            results[i]['avg'] = Math.round((solves[0] + solves[1] + solves[2] + solves[3] - Math.min(...solves) - Math.max(...solves)) / 2);
            results[i]['expand'] = {best: null, worst: null};
            results[i]['expand']['best'] = Math.round((solves[0] + solves[1] + solves[2] + solves[3] - Math.max(...solves)) / 3);
            if (dnf.length === 1) results[i]['expand']['worst'] = -1
            else results[i]['expand']['worst'] = Math.round((solves[0] + solves[1] + solves[2] + solves[3] - Math.min(...solves)) / 3);
          }
        }

        // sort and add places
        results = results.sort((x, y) => {
          if (x.avg > 0 && y.avg <= 0) return -1;
          if (y.avg > 0 && x.avg <= 0) return 1;
          if (x.avg !== y.avg) return x.avg - y.avg;
          return x.best - y.best;
        });
        results = this.addPlaces(results, 'a');
      }

      // calculate stats for each attempt
      let attempts = [0, 0, 0, 0, 0, 0, 0];
      let successes = [0, 0, 0, 0, 0, 0, 0];
      let sums = [0, 0, 0, 0, 0, 0, 0];
      let bests = [999999999999, 999999999999, 999999999999, 999999999999, 999999999999, 999999999999, 999999999999];
      let worsts = [-1, -1, -1, -1, -1, -1, -1];
      for (let i = 0; i < results.length; i++) {
        for (let j = 1; j < 6; j++) {
          let r = results[i]['a' + j], a = j - 1;
          if (r !== 0) attempts[a]++;
          if (r > 0) {
            successes[a]++;
            sums[a] += results[i]['a' + j];
          }
          if (r > 0 && r < bests[a]) bests[a] = r;
          if (r > 0 && r > worsts[a] && worsts[a] > 0) worsts[a] = r;
          else if (r < 0) worsts[a] = -1;
        }
      }

      // colorize attempts
      for (let i = 0; i < results.length; i++) {
        for (let j = 1; j < 6; j++) {
          let r = results[i]['a' + j], a = j - 1;
          if (r === bests[a]) results[i]['a' + j + 'c'] = 'success--text';
          else if (r === worsts[a]) results[i]['a' + j + 'c'] = 'error--text';
          else results[i]['a' + j + 'c'] = '';
        }
      }

      // averages for every attempt
      if (data.event.id !== '333mbf') results.push({
        place: '', name: '', avg: 0, best: 0, index: -1, expand: null,
        a1: Math.round(successes[0] !== 0 ? sums[0] / successes[0] : 0), a1c: 'warning--text',
        a2: Math.round(successes[1] !== 0 ? sums[1] / successes[1] : 0), a2c: 'warning--text',
        a3: Math.round(successes[2] !== 0 ? sums[2] / successes[2] : 0), a3c: 'warning--text',
        a4: Math.round(successes[3] !== 0 ? sums[3] / successes[3] : 0), a4c: 'warning--text',
        a5: Math.round(successes[4] !== 0 ? sums[4] / successes[4] : 0), a5c: 'warning--text'
      });

      // show expands
      for (let i = 0; i < results.length; i++)
        if (results[i].expand != null)
          this.expanded.push(results[i]);

      console.log('updated data');
      this.items = results;
    },
    updateHeaders(format) {
      let headers = [
        {text: '#', align: 'right', value: 'place'},
        {text: 'ZAWODNIK', align: 'left', value: 'name'},
        {text: '1', align: 'center', value: 'a1'}
      ];
      if (['2', '3', 'm', 'a'].includes(format))
        headers.push({text: '2', align: 'center', value: 'a2'});
      if (['3', 'm', 'a'].includes(format))
        headers.push({text: '3', align: 'center', value: 'a3'});
      if (format === 'a')
        headers.push({text: '4', align: 'center', value: 'a4'}, {text: '5', align: 'center', value: 'a5'});
      if (['m', 'a'].includes(format))
        headers.push({text: 'AVG', align: 'center', value: 'avg'}, {text: 'BEST', align: 'center', value: 'best'});
      else if (format === '3')
        headers.push({text: 'BEST', align: 'center', value: 'best'}, {text: 'AVG', align: 'center', value: 'avg'});
      else headers.push({text: 'BEST', align: 'center', value: 'best'});
      this.headers = headers;
    },
    addPlaces(results, type) {
      if (type === 'a') {
        let lastPlace = -3, lastAvg = -3, lastBest = -3;
        for (let i = 0; i < results.length; i++) {
          if (results[i]['avg'] === 0) {
            results[i]['place'] = '';
          } else if (lastAvg === results[i]['avg'] && lastBest === results[i]['best'])
            results[i]['place'] = lastPlace;
          else {
            results[i]['place'] = i + 1;
            lastPlace = i + 1;
            lastAvg = results[i]['avg'];
            lastBest = results[i]['best'];
          }
        }
      } else {
        let lastPlace = -3, lastBest = -3;
        for (let i = 0; i < results.length; i++) {
          if (lastBest === results[i]['best'])
            results[i]['place'] = lastPlace;
          else {
            results[i]['place'] = i + 1;
            lastPlace = i + 1;
            lastBest = results[i]['best'];
          }
        }
      }
      return results;
    }
  }
};
</script>
