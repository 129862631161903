var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":"","tile":""}},[_c('v-data-table',{attrs:{"dense":"","disable-sort":"","hide-default-footer":"","items-per-page":-1,"headers":_vm.headers,"items":_vm.items,"hide-default-header":"","expanded":_vm.expanded,"item-key":"index"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',_vm._l((headers),function(h){return _c('th',{key:h.value,class:'text-' + h.align,staticStyle:{"font-size":"19px","background-color":"#414141"}},[_vm._v(" "+_vm._s(h.text)+" ")])}),0)])]}},{key:"item.place",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(item.index >= 0)?_c('td',{staticClass:"text-right",staticStyle:{"font-size":"19px"},attrs:{"nowrap":""}},[_vm._v(" "+_vm._s(item.place)+" ")]):_c('td',{staticClass:"text-center",staticStyle:{"font-size":"19px","background-color":"#414141"},attrs:{"nowrap":""}},[_vm._v(" "+_vm._s(item.place)+" ")])]}},{key:"item.name",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(item.index >= 0)?_c('td',{staticClass:"text-left",staticStyle:{"font-size":"19px"},attrs:{"nowrap":""}},[_vm._v(" "+_vm._s(item.name)+" ")]):_c('td',{staticClass:"text-center",staticStyle:{"font-size":"19px","background-color":"#414141"},attrs:{"nowrap":""}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.a1",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(item.index >= 0)?_c('td',{staticClass:"text-center",class:[item['a1c']],staticStyle:{"font-size":"19px"},attrs:{"nowrap":""}},[_vm._v(" "+_vm._s(_vm.$utils.results.parseResult(item['a1'], _vm.event))+" ")]):_c('td',{staticClass:"text-center",class:[item['a1c']],staticStyle:{"font-size":"19px","background-color":"#414141"},attrs:{"nowrap":""}},[_vm._v(" "+_vm._s(_vm.$utils.results.parseResult(item['a1'], _vm.event))+" ")])]}},{key:"item.a2",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(item.index >= 0)?_c('td',{staticClass:"text-center",class:[item['a2c']],staticStyle:{"font-size":"19px"},attrs:{"nowrap":""}},[_vm._v(" "+_vm._s(_vm.$utils.results.parseResult(item['a2'], _vm.event))+" ")]):_c('td',{staticClass:"text-center",class:[item['a2c']],staticStyle:{"font-size":"19px","background-color":"#414141"},attrs:{"nowrap":""}},[_vm._v(" "+_vm._s(_vm.$utils.results.parseResult(item['a2'], _vm.event))+" ")])]}},{key:"item.a3",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(item.index >= 0)?_c('td',{staticClass:"text-center",class:[item['a3c']],staticStyle:{"font-size":"19px"},attrs:{"nowrap":""}},[_vm._v(" "+_vm._s(_vm.$utils.results.parseResult(item['a3'], _vm.event))+" ")]):_c('td',{staticClass:"text-center",class:[item['a3c']],staticStyle:{"font-size":"19px","background-color":"#414141"},attrs:{"nowrap":""}},[_vm._v(" "+_vm._s(_vm.$utils.results.parseResult(item['a3'], _vm.event))+" ")])]}},{key:"item.a4",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(item.index >= 0)?_c('td',{staticClass:"text-center",class:[item['a4c']],staticStyle:{"font-size":"19px"},attrs:{"nowrap":""}},[_vm._v(" "+_vm._s(_vm.$utils.results.parseResult(item['a4'], _vm.event))+" ")]):_c('td',{staticClass:"text-center",class:[item['a4c']],staticStyle:{"font-size":"19px","background-color":"#414141"},attrs:{"nowrap":""}},[_vm._v(" "+_vm._s(_vm.$utils.results.parseResult(item['a4'], _vm.event))+" ")])]}},{key:"item.a5",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(item.index >= 0)?_c('td',{staticClass:"text-center",class:[item['a5c']],staticStyle:{"font-size":"19px"},attrs:{"nowrap":""}},[_vm._v(" "+_vm._s(_vm.$utils.results.parseResult(item['a5'], _vm.event))+" ")]):_c('td',{staticClass:"text-center",class:[item['a5c']],staticStyle:{"font-size":"19px","background-color":"#414141"},attrs:{"nowrap":""}},[_vm._v(" "+_vm._s(_vm.$utils.results.parseResult(item['a5'], _vm.event))+" ")])]}},{key:"item.best",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(_vm.highlight === 'b' || item.index < 0)?_c('td',{staticClass:"font-weight-bold text-center",staticStyle:{"font-size":"19px","background-color":"#414141"},attrs:{"nowrap":""}},[_vm._v(" "+_vm._s(_vm.$utils.results.parseResult(item['best'], _vm.event))+" ")]):_c('td',{staticClass:"font-weight-bold text-center",staticStyle:{"font-size":"19px"},attrs:{"nowrap":""}},[_vm._v(" "+_vm._s(_vm.$utils.results.parseResult(item['best'], _vm.event))+" ")])]}},{key:"item.avg",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(_vm.highlight === 'a' || item.index < 0)?_c('td',{staticClass:"font-weight-bold text-center",staticStyle:{"font-size":"19px","background-color":"#414141"}},[_vm._v(" "+_vm._s(_vm.$utils.results.parseResult(item['avg'], _vm.event))+" ")]):_c('td',{staticClass:"font-weight-bold text-center",staticStyle:{"font-size":"19px"},attrs:{"nowrap":""}},[_vm._v(" "+_vm._s(_vm.$utils.results.parseResult(item['avg'], _vm.event))+" ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length - 2}},[_vm._v(" ")]),(item.expand != null)?_c('td',{staticClass:"text-center",staticStyle:{"font-size":"19px","background-color":"#414141"},attrs:{"nowrap":""}},[_c('span',{staticClass:"success--text"},[_vm._v(_vm._s(_vm.$utils.results.parseResult(item.expand.best, _vm.event)))]),_vm._v(" / "),_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.$utils.results.parseResult(item.expand.worst, _vm.event)))])]):_c('td',[_vm._v(" ")]),_c('td',[_vm._v(" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }